import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import Mixpanel from '@smartpay/mixpanel';
import { useCallback, useEffect, useState } from 'react';

import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import { USER_CREATION_SCREEN } from '../../constants';
import TogglerRow from './TogglerRow';
import PermissionRow from './PermissionRow';
import {
  useCorporateUserQuery,
  useUpsertCorporateUserMutation,
  useUpsertUserMutation,
  useUserQuery,
} from '../../services/users';

import styles from './UserModal.module.scss';
import useViewMode from '../../hooks/use-view-mode';

interface Props {
  type?: 'branch' | 'merchant' | 'corporate';
  id?: string | null | undefined;
  hide: () => void;
}

type UserRole = 'owner' | 'manager' | 'custom' | '';

export type PermissionValue =
  | 'viewer'
  | 'editor'
  | 'viewer-pii'
  | 'editor-pii'
  | '';

interface CreateUserFormInput {
  emailAddress: string;
  role: UserRole;
  ordersPermission: PermissionValue;
  refundsPermission: PermissionValue;
  couponsPermission: PermissionValue;
  paymentLinksPermission: PermissionValue;
  payoutPermission: PermissionValue;
  settingsPermission: PermissionValue;
}

const getRefundsPermissionInput = (roles: string[]) => {
  if (roles.includes('RefundsEditor')) {
    return 'editor';
  }
  if (roles.includes('RefundsViewer')) {
    return 'viewer';
  }

  return '';
};

const getPayoutsPermissionInput = (roles: string[]) => {
  if (roles.includes('PayoutsEditor')) {
    return 'editor';
  }
  if (roles.includes('PayoutsViewer')) {
    return 'viewer';
  }

  return '';
};

const getCouponsPermissionInput = (roles: string[]) => {
  if (roles.includes('CouponsEditor')) {
    return 'editor';
  }
  if (roles.includes('CouponsViewer')) {
    return 'viewer';
  }

  return '';
};

const getPayLinksPermissionInput = (roles: string[]) => {
  if (roles.includes('PayLinksEditor')) {
    return 'editor';
  }
  if (roles.includes('PayLinksViewer')) {
    return 'viewer';
  }

  return '';
};

const getSettingsPermissionInput = (roles: string[]) => {
  if (roles.includes('SettingsEditor')) {
    return 'editor';
  }
  if (roles.includes('SettingsViewer')) {
    return 'viewer';
  }

  return '';
};

const getOrdersPermissionInput = (roles: string[]) => {
  if (roles.includes('OrdersWithPersonalInfoEditor')) {
    return 'editor-pii';
  }
  if (roles.includes('OrdersWithPersonalInfoViewer')) {
    return 'viewer-pii';
  }
  if (roles.includes('OrdersEditor')) {
    return 'editor';
  }
  if (roles.includes('OrdersViewer')) {
    return 'viewer';
  }

  return '';
};

const getNewRole = (v: boolean, role: UserRole, inputRole: UserRole) => {
  if (v) {
    return role;
  }

  return role === inputRole ? '' : role;
};

const UserModal = ({ type = 'corporate', id = null, hide }: Props) => {
  const { t } = useTranslation('translation');
  const { merchantId } = useViewMode();

  const [isProcessing, setIsProcessing] = useState(false);
  const { data: merchantUser } = useUserQuery(
    { id: id as string, merchant: merchantId },
    { skip: !id || type !== 'merchant' }
  );
  const { data: corporateUser } = useCorporateUserQuery(
    { id: id as string },
    { skip: !id || type !== 'corporate' }
  );
  const userData = type === 'corporate' ? corporateUser : merchantUser;
  const [upsertCorporateUser] = useUpsertCorporateUserMutation();
  const [upsertMerchantUser] = useUpsertUserMutation();
  const upsertUser =
    type === 'corporate' ? upsertCorporateUser : upsertMerchantUser;

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { isValid },
  } = useForm<CreateUserFormInput>({
    defaultValues: {
      role: '',
      ordersPermission: '',
      refundsPermission: '',
      couponsPermission: '',
      paymentLinksPermission: '',
      payoutPermission: '',
      settingsPermission: '',
    },
  });

  const inputRole = watch('role');

  useEffect(() => {
    if (userData) {
      const dataRoles =
        type === 'corporate' ? userData?.corporateAccountRoles : [];

      const isCorporateManager = dataRoles.includes('CorporateManager');
      const isManager = dataRoles.includes('Manager');

      const corporateRole = isCorporateManager ? 'manager' : 'custom';
      const branchRole = isManager ? 'manager' : 'custom';
      const currentRole = type === 'corporate' ? corporateRole : branchRole;

      reset({
        emailAddress: userData?.username,
        role: currentRole,
        ordersPermission: getOrdersPermissionInput(dataRoles),
        refundsPermission: getRefundsPermissionInput(dataRoles),
        couponsPermission: getCouponsPermissionInput(dataRoles),
        paymentLinksPermission: getPayLinksPermissionInput(dataRoles),
        payoutPermission: getPayoutsPermissionInput(dataRoles),
        settingsPermission: getSettingsPermissionInput(dataRoles),
      });
    }
  }, [reset, type, userData]);

  const onSubmit = useCallback(
    async (data: CreateUserFormInput) => {
      Mixpanel.trackAction({
        screen: USER_CREATION_SCREEN,
        action: 'Click',
        itemName: `${id ? 'Update' : 'Create'} User`,
      });

      setIsProcessing(true);

      const {
        emailAddress,
        role,
        ordersPermission,
        refundsPermission,
        couponsPermission,
        paymentLinksPermission,
        payoutPermission,
        settingsPermission,
      } = data;

      const roles = [];

      if (role === 'manager') {
        roles.push(type === 'corporate' ? 'CorporateManager' : 'Manager');
      } else if (role === 'custom') {
        if (ordersPermission === 'viewer') {
          roles.push('OrdersViewer');
        } else if (ordersPermission === 'viewer-pii') {
          roles.push('OrdersWithPersonalInfoViewer');
        } else if (ordersPermission === 'editor') {
          roles.push('OrdersEditor');
        } else if (ordersPermission === 'editor-pii') {
          roles.push('OrdersWithPersonalInfoEditor');
        }

        if (refundsPermission === 'viewer') {
          roles.push('RefundsViewer');
        } else if (refundsPermission === 'editor') {
          roles.push('RefundsEditor');
        }

        if (couponsPermission === 'viewer') {
          roles.push('CouponsViewer');
        } else if (couponsPermission === 'editor') {
          roles.push('CouponsEditor');
        }

        if (paymentLinksPermission === 'viewer') {
          roles.push('PayLinksViewer');
        } else if (paymentLinksPermission === 'editor') {
          roles.push('PayLinksEditor');
        }

        if (payoutPermission === 'viewer') {
          roles.push('PayoutsViewer');
        } else if (payoutPermission === 'editor') {
          roles.push('PayoutsEditor');
        }

        if (settingsPermission === 'viewer') {
          roles.push('SettingsViewer');
        } else if (settingsPermission === 'editor') {
          roles.push('SettingsEditor');
        }
      }

      try {
        const result = await upsertUser({
          emailAddress,
          roles,
          merchant: merchantId,
        });

        if ('error' in result) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          throw new Error((result.error as any).data || 'unexpected_error');
        }

        hide();
      } catch (error) {
        // TODO: set error msg
        setIsProcessing(false);
      }
    },
    [hide, id, merchantId, type, upsertUser]
  );

  return (
    <div className={styles['modal-background']}>
      <div className={cx(styles.modal)}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={cx(styles.cols, styles.compact)}>
            <div className={cx(styles.col, styles.compact)}>
              <div className="header">
                <h3 id="create-branch-modal-title">
                  {t(`user-modal.title.${id ? 'create' : 'update'}`)}
                </h3>
              </div>
              <div className={styles.row}>
                <TextInput
                  label={t('user-modal.label.email')}
                  {...register('emailAddress')}
                  disabled={!!id}
                  readOnly={!!id}
                />
              </div>
              <div className={styles.row}>
                <TogglerRow
                  name="role-manager"
                  label={t('user-modal.label.manager')}
                  desc={t(`user-modal.label.${type}-manager-desc`)}
                  value={inputRole === 'manager'}
                  setValue={(v) =>
                    setValue('role', getNewRole(v, 'manager', inputRole))
                  }
                />
              </div>
              <div className={styles.row}>
                <TogglerRow
                  name="role-custom"
                  label={t('user-modal.label.custom-role')}
                  desc={t('user-modal.label.custom-role-desc')}
                  value={inputRole === 'custom'}
                  setValue={(v) =>
                    setValue('role', getNewRole(v, 'custom', inputRole))
                  }
                />
              </div>
            </div>
            {inputRole === 'custom' && (
              <div className={cx(styles.col, styles.compact)}>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-orders"
                    label={t('user-modal.label.role-orders')}
                    desc={t('user-modal.label.role-orders-desc')}
                    value={watch('ordersPermission')}
                    setValue={(v) => setValue('ordersPermission', v)}
                    pii
                  />
                </div>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-refunds"
                    label={t('user-modal.label.role-refunds')}
                    desc={t('user-modal.label.role-refunds-desc')}
                    value={watch('refundsPermission')}
                    setValue={(v) => setValue('refundsPermission', v)}
                  />
                </div>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-coupons"
                    label={t('user-modal.label.role-coupons')}
                    desc={t('user-modal.label.role-coupons-desc')}
                    value={watch('couponsPermission')}
                    setValue={(v) => setValue('couponsPermission', v)}
                  />
                </div>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-pay-links"
                    label={t('user-modal.label.role-pay-links')}
                    desc={t('user-modal.label.role-pay-links-desc')}
                    value={watch('paymentLinksPermission')}
                    setValue={(v) => setValue('paymentLinksPermission', v)}
                  />
                </div>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-payout"
                    label={t('user-modal.label.role-payout')}
                    desc={t('user-modal.label.role-payout-desc')}
                    value={watch('payoutPermission')}
                    setValue={(v) => setValue('payoutPermission', v)}
                  />
                </div>
                <div className={styles.row}>
                  <PermissionRow
                    name="permission-settings"
                    label={t('user-modal.label.role-settings')}
                    desc={t('user-modal.label.role-settings-desc')}
                    value={watch('settingsPermission')}
                    setValue={(v) => setValue('settingsPermission', v)}
                  />
                </div>
              </div>
            )}
          </div>
          <div className={cx(styles.row, styles.footer)}>
            <Button
              type="button"
              onClick={hide}
              variant="outline"
              size="small"
              label={t('cancel-btn')}
            />
            <Button
              type="submit"
              size="small"
              disabled={!isValid}
              label={t(`user-modal.button.${id ? 'update' : 'create'}`)}
              processing={isProcessing}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserModal;

import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams } from 'use-query-params';

import Capsule from '../../components/Capsule/Capsule';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import PaginationView from '../../components/PaginationView/PaginationView';
import Toggle from '../../components/Toggle/Toggle';
import {
  PROMOTION_CODE_DETAIL_PAGE_SIZE,
  PROMOTION_CODE_DETAIL_SCREEN,
  SMARTPAY_DOCUMENT_URL,
} from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { updatePromotionCode } from '../../redux/coupon';
import { useOrdersQuery } from '../../services/order';
import createEventHandler from '../../utils/create-event-handler';
import {
  couponDetails,
  formatCurrency,
  useDataMode,
  useDataModeChanged,
} from '../../utils/helper';
import PromotionCodeOrderRow from './PromotionCodeOrderRow';
import Time from '../../components/Time/Time';
import {
  useMerchantCouponQuery,
  useMerchantPromotionCodeQuery,
} from '../../hooks/use-merchant-queries';

import styles from './PromotionCodeDetailScreen.module.scss';

interface CouponDetailProps extends RouteComponentProps {
  couponId?: string;
  promotionCodeId?: string;
}

const screen = PROMOTION_CODE_DETAIL_SCREEN;

const PromotionCodeDetailScreen: FC<CouponDetailProps> = ({
  couponId = '',
  promotionCodeId = '',
}) => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');
  const { test: isTestDataMode } = useDataMode();
  const dispatch = useAppDispatch();

  useDataModeChanged({
    id: promotionCodeId,
    onDataModeChanged: () => {
      navigate('/coupons');
    },
  });

  const { data: coupon, isLoading: isCouponLoading } = useMerchantCouponQuery({
    id: couponId,
  });
  const {
    data: promotionCode,
    isLoading,
    refetch,
  } = useMerchantPromotionCodeQuery({ id: promotionCodeId });

  const [query, setQuery] = useQueryParams({ offset: NumberParam });
  const offset = query.offset || 0;
  const orderParams = {
    offset,
    limit: PROMOTION_CODE_DETAIL_PAGE_SIZE,
    promotionCode: promotionCodeId,
    test: isTestDataMode,
  };

  const { data: ordersResponse, isLoading: isOrderLoading } =
    useOrdersQuery(orderParams);
  const hasNextPage = !!ordersResponse?.nextOffset;

  if (
    isLoading ||
    isCouponLoading ||
    !promotionCode ||
    !promotionCodeId ||
    !coupon
  ) {
    return <Loading />;
  }

  const metadata = promotionCode?.metadata;
  const { isFinished, isExpired, isEnded } = couponDetails(promotionCode);

  const updatePromotionCodeAsync = createEventHandler(
    async (checked: boolean) => {
      const resultAction = await dispatch(
        updatePromotionCode({
          promotionCodeId: promotionCode.id,
          active: checked,
        })
      );

      if (updatePromotionCode.fulfilled.match(resultAction)) {
        refetch();
      }
    }
  );

  return (
    <Layout>
      <div className={styles['navigation-bar']}>
        <Button
          id="btn_back"
          label={t('back-btn')}
          variant="outline"
          size="small"
          onClick={() => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: 'Back',
            });

            navigate(-1);
          }}
        />
        <h1 className={coupon.active ? '' : styles.inactive}>{coupon.name}</h1>
        <h1 className={promotionCode.active ? '' : styles.inactive}>
          {promotionCode.code}
        </h1>
        {isEnded && (
          <Capsule
            title={t('promotion-code.status.finished')}
            size="small"
            color="cerise"
          />
        )}
        <Toggle
          leftText={t('promotion-code.status.inactive')}
          rightText={t('promotion-code.status.active')}
          checked={promotionCode.active}
          onChange={(checked) => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: checked
                ? 'Active Promotion Code'
                : 'Inactive Promotion Code',
            });

            updatePromotionCodeAsync(checked);
          }}
          disabled={isEnded}
        />
      </div>
      <div className={styles['promotion-code-detail-container']}>
        <section>
          <TextInput
            name="promotion_code_id"
            type="text"
            variant="clean"
            value={promotionCode.id}
            rightIcon="copy"
            rightIconColor="gray"
            size={promotionCode.id.length}
            onRightIconClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Copy Promotion Code Id',
              });
            }}
            readOnly
          />
        </section>
        <section className={styles.details}>
          <h2>{t('promotion-code.details.title')}</h2>
          <div className={isFinished ? styles.finished : ''}>
            <b>
              {promotionCode.redemptionCount}
              {promotionCode.maxRedemptionCount &&
                `/${promotionCode.maxRedemptionCount}`}
            </b>
            <i>{t('promotion-code.details.number-of-redemptions')}</i>
          </div>
          {promotionCode.minimumAmount && (
            <div>
              <b>
                {formatCurrency(
                  promotionCode.minimumAmount,
                  promotionCode.currency
                )}
              </b>
              <i>{t('promotion-code.details.minimum-amount')}</i>
            </div>
          )}
          {promotionCode.onePerCustomer && (
            <div>
              <i>{t('promotion-code.details.one-per-customer')}</i>
            </div>
          )}
          <div>
            <span>
              <Time
                dateTime={promotionCode.createdAt}
                onEmpty="ー"
                formatStr="y/MM/dd"
              />
              <i>{t('promotion-code.details.created-date')}</i>
            </span>
            <span
              className={cx(
                styles['expiry-date'],
                isExpired ? styles.expired : ''
              )}
            >
              <Time
                dateTime={promotionCode.expiresAt}
                onEmpty="ー"
                formatDateStr="y/MM/dd"
                formatTimeStr="HH:mm"
              />
              <i>{t('promotion-code.details.expiration-date')}</i>
            </span>
          </div>
        </section>
        <section>
          <h2>{t('promotion-code.valid-redemption.title')}</h2>
          <table className={styles.table}>
            <thead>
              <tr>
                <th>
                  {t('promotion-code.valid-redemption.table-header.orders')}
                </th>
                <th>
                  {t('promotion-code.valid-redemption.table-header.code')}
                </th>
                <th className={styles.amount}>
                  {t('promotion-code.valid-redemption.table-header.amount')}
                </th>
                <th>
                  {t(
                    'promotion-code.valid-redemption.table-header.created-date'
                  )}
                </th>
              </tr>
            </thead>
            <tbody>
              {!isOrderLoading &&
                ordersResponse &&
                ordersResponse.data.map((order) => (
                  <PromotionCodeOrderRow
                    order={order}
                    promotionCode={promotionCode.code}
                  />
                ))}
            </tbody>
          </table>

          {isOrderLoading && <Loading width={48} top={48} />}

          {!isOrderLoading &&
          ordersResponse &&
          ordersResponse?.data.length > 0 ? (
            <PaginationView
              hasPrevPage={offset > 0}
              hasNextPage={hasNextPage}
              screen={screen}
              onPrevClick={() =>
                setQuery(
                  {
                    offset: offset - PROMOTION_CODE_DETAIL_PAGE_SIZE,
                  },
                  'replaceIn'
                )
              }
              onNextClick={() =>
                setQuery(
                  {
                    offset: ordersResponse?.nextOffset,
                  },
                  'replaceIn'
                )
              }
            />
          ) : (
            <EmptyStateView
              title={t('promotion-code.valid-redemption.empty-state.title')}
              size="small"
            >
              <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
            </EmptyStateView>
          )}
        </section>
        {metadata && Object.keys(metadata).length > 0 && (
          <section className={styles['meta-container']}>
            <h2>{t('promotion-code.metadata')}</h2>
            {Object.keys(metadata).map((key) => {
              return (
                <div>
                  <div>{key}</div>
                  <div>{metadata[key]}</div>
                </div>
              );
            })}
          </section>
        )}
      </div>
    </Layout>
  );
};

export default PromotionCodeDetailScreen;

import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useInterval from 'use-interval';
import { NumberParam, useQueryParams } from 'use-query-params';
import { Item, Menu } from 'react-contexify';

import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Layout from '../../components/Layout';
import PaginationView from '../../components/PaginationView/PaginationView';
import { DEFAULT_PAGE_SIZE, USERS_SCREEN } from '../../constants';
import { useDataMode } from '../../utils/helper';
import UserRow from './UserRow';
import Loading from '../../components/Loading';
import { useCorporateUsersQuery } from '../../services/users';
import CorporateNav from '../../components/CorporateNav/CorporateNav';
import UserModal from './UserModal';
import Button from '../../components/Form/Button';
import { useCorporateLegalEntityQuery } from '../../services/setting';

import IconOrdersActive from '../../assets/icon-sidebar-order-active.svg';

import styles from './UserListScreen.module.scss';
import DeleteUserModal from './DeleteUserModal';

const screen = USERS_SCREEN;

const CorporateUserListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');

  const [isShowCreateUserModal, setIsShowCreateUserModal] = useState(false);
  const [isShowEditUserModal, setIsShowEditUserModal] = useState(false);
  const [isShowDeleteUserModal, setIsShowDeleteUserModal] = useState(false);

  const [activeUserId, setActiveUserId] = useState('');

  const { data: legalEntity } = useCorporateLegalEntityQuery();
  const { test: isTestDataMode } = useDataMode();

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;

  const { data, isLoading, isFetching, refetch } = useCorporateUsersQuery(
    {
      offset,
      test: isTestDataMode,
      limit: DEFAULT_PAGE_SIZE,
    },
    { refetchOnMountOrArgChange: 60 }
  );

  const users = data?.data;

  const onShowEditUserModal = useCallback((id: string) => {
    setActiveUserId(id);
    setIsShowEditUserModal(true);
  }, []);

  const onShowDeleteUserModal = useCallback((id: string) => {
    setActiveUserId(id);
    setIsShowDeleteUserModal(true);
  }, []);

  useInterval(
    () => {
      setQuery({
        offset,
      });
    },
    offset === 0 ? 30000 : null
  ); // refetch every 30 seconds, roughly

  return (
    <Layout
      title={`${legalEntity?.businessInfo.name} ${t('corporate.title')}`}
      isLoading={!users && isLoading}
    >
      <CorporateNav
        actions={
          <Button
            label={t('user-modal.button.create-new-user')}
            size="small"
            onClick={() => {
              setActiveUserId('');
              setIsShowCreateUserModal(true);
            }}
          />
        }
      />

      <table className={styles.table}>
        <thead>
          <tr>
            <th>{t('user-listing.table-header.user-email')}</th>
            <th>{t('user-listing.table-header.access')}</th>
            <th>{t('user-listing.table-header.created-at')}</th>
            <th className={styles.manager}> </th>
          </tr>
        </thead>
        <tbody>
          {users &&
            !isFetching &&
            users.length > 0 &&
            users.map((user) => <UserRow key={user.id} user={user} />)}
        </tbody>
      </table>

      <Menu id="user_context_menu">
        <Item onClick={({ props }) => onShowEditUserModal(props.id)}>
          {t('user-listing.menu.edit-user')}
        </Item>
        <Item onClick={({ props }) => onShowDeleteUserModal(props.id)}>
          {t('user-listing.menu.delete-user')}
        </Item>
      </Menu>

      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
      {!isFetching &&
        (users?.length === 0 ? (
          <EmptyStateView
            src={IconOrdersActive}
            title={t('user-listing.search-result.no-orders')}
          />
        ) : (
          <PaginationView
            screen={screen}
            hasPrevPage={offset > 0}
            hasNextPage={!!data?.nextOffset}
            onPrevClick={() => {
              setQuery(
                {
                  offset: offset - DEFAULT_PAGE_SIZE,
                },
                'replaceIn'
              );
            }}
            onNextClick={() =>
              setQuery(
                {
                  offset: data?.nextOffset,
                },
                'replaceIn'
              )
            }
          />
        ))}
      {isShowCreateUserModal && (
        <UserModal
          hide={() => {
            refetch();
            setIsShowCreateUserModal(false);
          }}
        />
      )}
      {isShowEditUserModal && (
        <UserModal
          id={activeUserId}
          hide={() => {
            refetch();
            setIsShowEditUserModal(false);
          }}
        />
      )}
      {isShowDeleteUserModal && (
        <DeleteUserModal
          id={activeUserId}
          hide={() => {
            refetch();
            setIsShowDeleteUserModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default CorporateUserListScreen;

import {
  AccountInfo,
  KeySet,
  BankAccount,
  FeePlan,
  PayoutSchedule,
  LegalEntity,
} from '../types/setting';
import baseAPI from './base';

export interface GetSettingsParams {
  merchant?: string;
}

export interface CreateBranchParams {
  name?: string;
}

export interface CreateBranchResult {
  data: { id: string };
}

export const settingAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    accountInfo: builder.query<AccountInfo, void>({
      query: () => ({
        url: `/me/info`,
      }),
    }),
    apiKeys: builder.query<KeySet[], GetSettingsParams>({
      query: (params) => ({
        url: `/me/api-keys`,
        params,
      }),
    }),
    legalEntity: builder.query<LegalEntity | undefined, GetSettingsParams>({
      query: (params) => ({
        url: `/me/merchant/legal-entity`,
        params,
      }),
    }),
    corporateLegalEntity: builder.query<LegalEntity | undefined, void>({
      query: () => ({
        url: `/me/corporate-account/legal-entity`,
      }),
    }),
    bankAccount: builder.query<BankAccount | undefined, GetSettingsParams>({
      query: (params) => ({
        url: `/me/merchant/bank-account`,
        params,
      }),
    }),
    corporateBankAccount: builder.query<BankAccount | undefined, void>({
      query: () => ({
        url: `/me/corporate-account/bank-account`,
      }),
    }),
    feePlan: builder.query<FeePlan | undefined, GetSettingsParams>({
      query: (params) => ({
        url: `/me/merchant/plan-fees`,
        params,
      }),
      transformResponse: (data: FeePlan[]) =>
        data.find((e: FeePlan) => e.active),
    }),
    corporateFeePlan: builder.query<FeePlan | undefined, void>({
      query: () => ({
        url: `/me/corporate-account/plan-fees`,
      }),
      transformResponse: (data: FeePlan[]) =>
        data.find((e: FeePlan) => e.active),
    }),
    payoutSchedule: builder.query<
      PayoutSchedule | undefined,
      GetSettingsParams
    >({
      query: (params) => ({
        url: `/me/merchant/current/payout-schedule`,
        params,
      }),
    }),
    corporatePayoutSchedule: builder.query<PayoutSchedule | undefined, void>({
      query: () => ({
        url: `/me/corporate-account/current/payout-schedule`,
      }),
    }),
    createBranch: builder.mutation<CreateBranchResult, CreateBranchParams>({
      query: (data) => ({
        url: `/me/merchants`,
        method: 'POST',
        data,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAccountInfoQuery,
  useLazyAccountInfoQuery,
  useLegalEntityQuery,
  useCorporateLegalEntityQuery,
  useApiKeysQuery,
  useFeePlanQuery,
  useCorporateFeePlanQuery,
  useBankAccountQuery,
  useCorporateBankAccountQuery,
  usePayoutScheduleQuery,
  useCorporatePayoutScheduleQuery,
  useCreateBranchMutation,
} = settingAPI;

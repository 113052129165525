import {
  globalHistory,
  Redirect,
  RouteComponentProps,
  Router,
} from '@reach/router';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import Mixpanel from '@smartpay/mixpanel';
import { parse } from 'query-string';
import { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import {
  BRANCHES_SCREEN,
  COUPONS_SCREEN,
  ERROR_SCREEN,
  INQUIRY_SCREEN,
  LOGIN_SCREEN,
  ORDERS_SCREEN,
  PAYOUTS_SCREEN,
  RESET_PASSWORD_SCREEN,
  SETTING_ACCOUNTS_SCREEN,
  SETTING_BRANDING_SCREEN,
  SETTING_CREDENTIALS_SCREEN,
  SETTING_PAYOUTS_SCREEN,
  TWOFA_SCREEN,
} from './constants';
import { updateMiscPairs } from './redux/misc';
import CouponDetailScreen from './screens/CouponDetailScreen/CouponDetailScreen';
import CouponListScreen from './screens/CouponListScreen/CouponListScreen';
import ErrorScreen from './screens/ErrorScreen/ErrorScreen';
import LoginScreen from './screens/LoginScreen/LoginScreen';
import BranchListScreen from './screens/BranchListScreen/BranchListScreen';
import OrderDetailScreen from './screens/OrderDetailScreen/OrderDetailScreen';
import OrderListScreen from './screens/OrderListScreen/OrderListScreen';
import PaymentLinkListScreen from './screens/PaymentLinkListScreen/List';
import PayoutDetailScreen from './screens/PayoutDetailScreen/PayoutDetailScreen';
import PayoutListScreen from './screens/PayoutListScreen/PayoutListScreen';
// eslint-disable-next-line max-len
import PromotionCodeDetailScreen from './screens/PromotionCodeDetailScreen/PromotionCodeDetailScreen';
import ResetPasswordScreen from './screens/ResetPasswordScreen/ResetPasswordScreen';
import ResetPasswordStep2Screen from './screens/ResetPasswordScreen/ResetPasswordStep2Screen';
import AccountsScreen from './screens/SettingsScreen/AccountsScreen';
import CredentialsScreen from './screens/SettingsScreen/CredentialsScreen';
import PaymentsScreen from './screens/SettingsScreen/PaymentsScreen';
import SettingsScreen from './screens/SettingsScreen/SettingsScreen';
import InquiryScreen from './screens/InquiryScreen/InquiryScreen';
import TwoFAScreen from './screens/LoginScreen/TwoFAScreen';

import CorporateOrderListScreen from './screens/OrderListScreen/CorporateOrderListScreen';
import CorporatePayoutListScreen from './screens/PayoutListScreen/CorporatePayoutListScreen';
import CorporateCouponListScreen from './screens/CouponListScreen/CorporateCouponListScreen';
import CorporateAccountsScreen from './screens/SettingsScreen/CorporateAccountsScreen';
import CorporatePaymentsScreen from './screens/SettingsScreen/CorporatePaymentsScreen';
import UserListScreen from './screens/UserListScreen/UserListScreen';
import CorporateUserListScreen from './screens/UserListScreen/CorporateUserListScreen';
import { useAccountInfoQuery } from './services/setting';

import 'react-contexify/ReactContexify.css';
// import '../../styles/_react-contextify.scss';

import './App.scss';

Sentry.init({
  dsn:
    !!process.env.REACT_APP_SKIP_SENTRY_INIT ||
    !window.location.host.startsWith('dashboard.smartpay.co')
      ? ''
      : 'https://a3a2251c4df1467faf5d864ff01f9ca5@o982838.ingest.sentry.io/5938324',
  release: `dashboard@${process.env.REACT_APP_VERSION}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,
  debug: process.env.NODE_ENV !== 'production',
});

Mixpanel.initialize({
  token: process.env.REACT_APP_MIXPANEL_TOKEN || '',
  disabled:
    !!process.env.REACT_APP_DISABLE_MIXPANEL ||
    !window.location.host.startsWith('dashboard.smartpay.co'),
  superProps: {
    Product: 'Merchant Dashboard',
    Platform: 'Web',
  },
  urlToScreen: {
    '/login': LOGIN_SCREEN,
    '/2fa': TWOFA_SCREEN,
    '/reset-password': RESET_PASSWORD_SCREEN,
    '/branches': BRANCHES_SCREEN,
    '/payouts': PAYOUTS_SCREEN,
    '/orders': ORDERS_SCREEN,
    '/coupons': COUPONS_SCREEN,
    '/settings/accounts': SETTING_ACCOUNTS_SCREEN,
    '/settings/payments': SETTING_PAYOUTS_SCREEN,
    '/settings/credentials': SETTING_CREDENTIALS_SCREEN,
    '/settings/branding': SETTING_BRANDING_SCREEN,
    '/inquiry': INQUIRY_SCREEN,
    '404': ERROR_SCREEN,
  },
});

const HomeScreen: FC<RouteComponentProps> = () => {
  const { data: merchantData } = useAccountInfoQuery();

  if (merchantData?.corporateAccountsFeatureEnabled) {
    return <Redirect to="/c/branches" noThrow />;
  }

  return <Redirect to="/orders" noThrow />;
};

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const params = parse(window.location.search);
    const referrer = params.referrer?.toString() || document?.referrer;
    const oneTimeToken = params.iotp?.toString();

    if (referrer) {
      dispatch(updateMiscPairs({ referrer }));
    }

    if (oneTimeToken) {
      dispatch(updateMiscPairs({ oneTimeToken }));
    }
  }, [dispatch]);

  return (
    <>
      <Router primary={false}>
        <QueryParamProvider {...{ path: '/' }} reachHistory={globalHistory}>
          <ErrorScreen path="404" default />
          <HomeScreen path="/" />
          <LoginScreen path="login" />
          <TwoFAScreen path="2fa" />
          <ResetPasswordScreen path="reset-password" />
          <ResetPasswordStep2Screen path="reset-password/:token" />

          <BranchListScreen path="/c/branches" />
          <CorporateAccountsScreen path="/c/account-info" />
          <CorporatePaymentsScreen path="/c/payout-info" />
          <CorporateUserListScreen path="/c/users" />

          <>
            <OrderListScreen path="orders" />
            <OrderDetailScreen path="orders/:orderId" />
            <CorporateOrderListScreen path="c/orders" />
            <OrderDetailScreen path="c/orders/:orderId" />
            <OrderListScreen path="m/:merchantId/orders" />
            <OrderDetailScreen path="m/:merchantId/orders/:orderId" />
          </>

          <>
            <PayoutListScreen path="payouts" />
            <PayoutDetailScreen path="payouts/:payoutId" />
            <CorporatePayoutListScreen path="c/payouts" />
            <PayoutDetailScreen path="c/payouts/:payoutId" />
            <PayoutListScreen path="m/:merchantId/payouts" />
            <PayoutDetailScreen path="m/:merchantId/payouts/:payoutId" />
          </>

          <>
            <CouponListScreen path="coupons" />
            <CouponDetailScreen path="coupons/:couponId" />
            <PromotionCodeDetailScreen path="coupons/:couponId/:promotionCodeId" />
            <CorporateCouponListScreen path="c/coupons" />
            <CouponDetailScreen path="c/coupons/:couponId" />
            <PromotionCodeDetailScreen path="c/coupons/:couponId/:promotionCodeId" />
            <CouponListScreen path="m/:merchantId/coupons" />
            <CouponDetailScreen path="m/:merchantId/coupons/:couponId" />
            <PromotionCodeDetailScreen path="m/:merchantId/coupons/:couponId/:promotionCodeId" />
          </>

          <>
            <PaymentLinkListScreen path="payment-links" />
            <PaymentLinkListScreen path="c/payment-links" />
            <PaymentLinkListScreen path="m/:merchantId/payment-links" />
          </>

          <UserListScreen path="users" />
          <SettingsScreen path="settings" />
          <AccountsScreen path="settings/accounts" />
          <PaymentsScreen path="settings/payments" />
          <CredentialsScreen path="settings/credentials" />

          <UserListScreen path="m/:merchantId/users" />
          <UserListScreen path="m/:merchantId/users" />
          <SettingsScreen path="m/:merchantId/settings" />
          <AccountsScreen path="m/:merchantId/settings/accounts" />
          <PaymentsScreen path="m/:merchantId/settings/payments" />
          <CredentialsScreen path="m/:merchantId/settings/credentials" />

          <InquiryScreen path="inquiry" />
          <InquiryScreen path="m/:merchantId/inquiry" />

          <Redirect from="error" to="/login" noThrow />
        </QueryParamProvider>
      </Router>
    </>
  );
};

export default App;

import { RouteComponentProps } from '@reach/router';
import { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Layout from '../../components/Layout';
import { BRANCHES_SCREEN } from '../../constants';
import BranchRow from './BranchRow';
import Loading from '../../components/Loading';
import {
  useAccountInfoQuery,
  useCorporateLegalEntityQuery,
} from '../../services/setting';
import CorporateNav from '../../components/CorporateNav/CorporateNav';
import CreateBranchModal from './CreateBranchModal';
import Button from '../../components/Form/Button';

import styles from './BranchListScreen.module.scss';

const screen = BRANCHES_SCREEN;

const BranchListScreen: FC<RouteComponentProps> = () => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');

  const [isShowModal, setIsShowModal] = useState(false);

  const { data: legalEntity } = useCorporateLegalEntityQuery();
  const { data, isFetching, isLoading, refetch } = useAccountInfoQuery();

  const branches = data?.merchants;

  return (
    <Layout
      title={`${legalEntity?.businessInfo.name} ${t('corporate.title')}`}
      isLoading={!branches && isLoading}
    >
      <CorporateNav
        actions={
          <Button
            label={t('create-branch-modal.button.create-a-branch')}
            size="small"
            onClick={() => {
              setIsShowModal(true);
            }}
          />
        }
      />

      <table className={styles.table}>
        <thead>
          <tr>
            <th className={styles.status}>
              {t('branch-listing.table-header.status')}
            </th>
            <th>{t('branch-listing.table-header.name')}</th>
            <th>{t('branch-listing.table-header.id')}</th>
            <th>{t('branch-listing.table-header.date')}</th>
          </tr>
        </thead>
        <tbody>
          {branches &&
            !isFetching &&
            branches.length > 0 &&
            branches.map((branch) => (
              <BranchRow key={branch.id} branch={branch} />
            ))}
        </tbody>
      </table>
      {isFetching && (
        <div className={styles['loading-wrapper']}>
          <Loading />
        </div>
      )}
      {isShowModal && (
        <CreateBranchModal
          hide={() => {
            refetch();
            setIsShowModal(false);
          }}
        />
      )}
    </Layout>
  );
};

export default BranchListScreen;

import { useCouponQuery, usePromotionCodeQuery } from '../services/coupon';
import { useOrderQuery, useTransactionsQuery } from '../services/order';
import { usePayoutQuery } from '../services/payout';
import {
  useApiKeysQuery,
  useBankAccountQuery,
  useFeePlanQuery,
  useLegalEntityQuery,
  usePayoutScheduleQuery,
} from '../services/setting';
import useViewMode from './use-view-mode';

interface GetEntryParams {
  id: string;
}

export const useMerchantLegalEntityQuery = () => {
  const { merchantId } = useViewMode();

  return useLegalEntityQuery({ merchant: merchantId });
};

export const useMerchantFeePlanQuery = () => {
  const { merchantId } = useViewMode();

  return useFeePlanQuery({ merchant: merchantId });
};

export const useMerchantBankAccountQuery = () => {
  const { merchantId } = useViewMode();

  return useBankAccountQuery({ merchant: merchantId });
};

export const useMerchantPayoutScheduleQuery = () => {
  const { merchantId } = useViewMode();

  return usePayoutScheduleQuery({ merchant: merchantId });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMerchantApiKeysQuery = (options?: any) => {
  const { merchantId } = useViewMode();

  return useApiKeysQuery({ merchant: merchantId }, options);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useMerchantOrderQuery = ({ id }: GetEntryParams, options: any) => {
  const { merchantId } = useViewMode();

  return useOrderQuery({ id, merchant: merchantId }, options);
};

export const useMerchantTransactionsQuery = (
  { id }: GetEntryParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  const { merchantId } = useViewMode();

  return useTransactionsQuery({ id, merchant: merchantId }, options);
};

export const useMerchantPayoutQuery = (
  { id }: GetEntryParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  const { merchantId } = useViewMode();

  return usePayoutQuery({ id, merchant: merchantId }, options);
};

export const useMerchantCouponQuery = (
  { id }: GetEntryParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  const { merchantId } = useViewMode();

  return useCouponQuery({ id, merchant: merchantId }, options);
};

export const useMerchantPromotionCodeQuery = (
  { id }: GetEntryParams,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options?: any
) => {
  const { merchantId } = useViewMode();

  return usePromotionCodeQuery({ id, merchant: merchantId }, options);
};

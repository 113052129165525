import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';

import useAppDispatch from '../../hooks/use-app-dispatch';
import { signOut } from '../../redux/auth';
import { orderAPI } from '../../services/order';
import { useAccountInfoQuery } from '../../services/setting';
import useViewMode from '../../hooks/use-view-mode';

import styles from './ProfilePopup.module.scss';

export const PROFILE_POPUP_SCREEN = 'Profile Popup';

const ProfilePopup = () => {
  useTrackPageView({ screen: PROFILE_POPUP_SCREEN });

  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation');

  const { data: accountInfo } = useAccountInfoQuery(undefined, {
    refetchOnMountOrArgChange: false,
  });
  const firstRef = useRef<HTMLAnchorElement>(null);
  const { base } = useViewMode();

  const profileMenu = (
    <div className={styles.content}>
      <span>{accountInfo?.username || ''}</span>
      <Link
        id="settings"
        to={`${base}/settings`}
        ref={firstRef}
        onClick={() =>
          Mixpanel.trackAction({
            screen: PROFILE_POPUP_SCREEN,
            action: 'Click',
            itemName: 'Settings',
          })
        }
      >
        {t('profile-popup.menu-item.settings')}
      </Link>
      <button
        id="logout"
        type="button"
        onClick={() => {
          Mixpanel.trackAction({
            screen: PROFILE_POPUP_SCREEN,
            action: 'Click',
            itemName: 'Logout',
          });
          dispatch(signOut());
          dispatch(orderAPI.util.resetApiState());
        }}
      >
        {t('profile-popup.menu-item.logout')}
      </button>
    </div>
  );

  useEffect(() => {
    if (firstRef.current) {
      firstRef.current.focus();
    }
  }, []);

  return (
    <div className={styles['profile-popup-container']}>
      <span className={styles['arrow-border']}>
        <span className={styles.arrow} />
      </span>
      {profileMenu}
    </div>
  );
};

export default ProfilePopup;

import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import cx from 'classnames';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberParam, useQueryParams } from 'use-query-params';

import Capsule from '../../components/Capsule/Capsule';
import EmptyStateView from '../../components/EmptyStateView/EmptyStateView';
import Button from '../../components/Form/Button';
import TextInput from '../../components/Form/TextInput';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import PaginationView from '../../components/PaginationView/PaginationView';
import Toggle from '../../components/Toggle/Toggle';
import {
  COUPON_DETAIL_SCREEN,
  DEFAULT_PAGE_SIZE,
  SMARTPAY_DOCUMENT_URL,
} from '../../constants';
import useAppDispatch from '../../hooks/use-app-dispatch';
import { updateCoupon } from '../../redux/coupon';
import { usePromotionCodesQuery } from '../../services/coupon';
import {
  couponDetails,
  formatCurrency,
  getCouponCondition,
  useDataMode,
  useDataModeChanged,
} from '../../utils/helper';
import CreatePromotionCodeModal from './CreatePromotionCodeModal';
import PromotionCodeRow from './PromotionCodeRow';
import Time from '../../components/Time/Time';
import { useMerchantCouponQuery } from '../../hooks/use-merchant-queries';
import useViewMode from '../../hooks/use-view-mode';

import styles from './CouponDetailScreen.module.scss';

interface CouponDetailProps extends RouteComponentProps {
  couponId?: string;
}

const screen = COUPON_DETAIL_SCREEN;

const CouponDetailScreen: FC<CouponDetailProps> = ({ couponId = '' }) => {
  useTrackPageView({ screen });

  const { t } = useTranslation('translation');
  const { merchantId } = useViewMode();
  const { test: isTestDataMode } = useDataMode();

  const dispatch = useAppDispatch();

  const [isShowModal, setIsShowModal] = useState(false);

  useDataModeChanged({
    id: couponId,
    onDataModeChanged: () => {
      setIsShowModal(false);
      navigate('/coupons');
    },
  });

  const [query, setQuery] = useQueryParams({
    offset: NumberParam,
  });
  const offset = query.offset || 0;

  const {
    data: coupon,
    isLoading,
    refetch,
  } = useMerchantCouponQuery({ id: couponId });
  const {
    data: promotionCodesData,
    isLoading: isPromotionCodeLoading,
    refetch: refetchPromotionCodes,
  } = usePromotionCodesQuery({
    couponId,
    merchant: merchantId,
    test: isTestDataMode,
    offset,
    limit: DEFAULT_PAGE_SIZE,
  });

  const promotionCodes = promotionCodesData?.data || [];

  if (isLoading || !coupon || !couponId) {
    return <Loading />;
  }

  const metadata = coupon?.metadata;
  const { isFinished, isExpired, isEnded } = couponDetails(coupon);

  const createPromotionCode = () => {
    Mixpanel.trackAction({
      screen,
      action: 'Click',
      itemName: 'Create Promotion Code',
    });

    setIsShowModal(true);
  };

  const updateCouponAsync = async (checked: boolean) => {
    const resultAction = await dispatch(
      updateCoupon({
        id: coupon.id,
        active: checked,
      })
    );

    if (updateCoupon.fulfilled.match(resultAction)) {
      refetch();
    }
  };

  return (
    <Layout>
      <div className={styles['navigation-bar']}>
        <Button
          id="btn_back"
          label={t('back-btn')}
          variant="outline"
          size="small"
          onClick={() => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: 'Back',
            });

            navigate(-1);
          }}
        />
        <h1 className={coupon.active ? '' : styles.inactive}>{coupon.name}</h1>
        {isEnded && (
          <Capsule
            title={t('coupon.status.finished')}
            size="small"
            color="cerise"
          />
        )}
        <Toggle
          leftText={t('coupon.status.inactive')}
          rightText={t('coupon.status.active')}
          checked={coupon.active}
          onChange={(checked) => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: checked ? 'Active Coupon' : 'Inactive Coupon',
            });

            updateCouponAsync(checked);
          }}
          disabled={isEnded}
        />
      </div>
      <div className={styles['coupon-detail-container']}>
        <section>
          <i>{getCouponCondition(coupon)}</i>
          <TextInput
            name="coupon_id"
            type="text"
            variant="clean"
            value={coupon.id}
            rightIcon="copy"
            rightIconColor="gray"
            size={coupon.id.length}
            onRightIconClick={() => {
              Mixpanel.trackAction({
                screen,
                action: 'Click',
                itemName: 'Copy Coupon Id',
              });
            }}
            readOnly
          />
        </section>
        <section className={styles.details}>
          <h2>{t('coupon.details.title')}</h2>
          <div>
            <>
              {coupon.discountType === 'amount' && (
                <b>{formatCurrency(coupon.discountAmount ?? 0, 'JPY')}</b>
              )}
              {coupon.discountType === 'percentage' && (
                <div className={styles['discount-detail']}>
                  {coupon.discountPercentage && (
                    <b>{coupon.discountPercentage ?? 0} %</b>
                  )}
                  {coupon.discountMax && (
                    <strong>{`${t('coupon.details.max')} ¥${
                      coupon.discountMax
                    }`}</strong>
                  )}
                </div>
              )}
              <i>{t('coupon.details.discount')}</i>
            </>
          </div>
          <div className={isFinished ? styles.finished : ''}>
            <b>
              {coupon.redemptionCount}
              {coupon.maxRedemptionCount && coupon.maxRedemptionCount > 0
                ? coupon.maxRedemptionCount && `/${coupon.maxRedemptionCount}`
                : ''}
            </b>
            <i>{t('coupon.details.number-of-redemptions')}</i>
          </div>
          <div>
            <span>
              <Time
                dateTime={coupon.createdAt}
                onEmpty="ー"
                formatStr="y/MM/dd"
              />
              <i>{t('coupon.details.created-date')}</i>
            </span>
            <span
              className={cx(
                styles['expiry-date'],
                isExpired ? styles.expired : ''
              )}
            >
              <Time
                dateTime={coupon.expiresAt}
                onEmpty="ー"
                formatDateStr="y/MM/dd"
                formatTimeStr="HH:mm"
              />
              <i>{t('coupon.details.expiration-date')}</i>
            </span>
          </div>
        </section>
        <section>
          <h2>{t('promotion-code.title')}</h2>
          <Button
            id="btn_create_promotion_code"
            className={styles['create-promotion-btn']}
            label={t('promotion-code.add-promotion-code-btn')}
            variant="outline"
            size="small"
            onClick={() => createPromotionCode()}
            disabled={isEnded}
          />
          <table className={styles.table}>
            <thead>
              <tr>
                <th className={styles['promotion-code']}>
                  {t('promotion-code.table-header.promotion-code')}
                </th>
                <th>{t('promotion-code.table-header.id')}</th>
                <th className={styles.redemptions}>
                  {t('promotion-code.table-header.number-of-redemptions')}
                </th>
                <th>{t('promotion-code.table-header.expiration-date')}</th>
                <th>{t('promotion-code.table-header.created-date')}</th>
              </tr>
            </thead>
            <tbody>
              {promotionCodes &&
                promotionCodes.map((promotionCode, i) => (
                  <PromotionCodeRow
                    key={i}
                    couponId={couponId}
                    promotionCode={promotionCode}
                  />
                ))}
            </tbody>
          </table>

          {isPromotionCodeLoading && <Loading width={48} top={48} />}

          {!isPromotionCodeLoading && promotionCodes.length === 0 ? (
            <EmptyStateView
              title={t('promotion-code.empty-state.title')}
              size="small"
            >
              <>
                <a href={SMARTPAY_DOCUMENT_URL}>{t('doc-url-desc')}</a>
                <Button
                  className={styles['create-promotion-code']}
                  size="small"
                  label={t('promotion-code.add-promotion-code-btn')}
                  onClick={() => createPromotionCode()}
                  disabled={isEnded}
                />
              </>
            </EmptyStateView>
          ) : (
            <PaginationView
              screen={screen}
              hasPrevPage={offset > 0}
              hasNextPage={!!promotionCodesData?.nextOffset}
              onPrevClick={() =>
                setQuery(
                  {
                    offset: offset - DEFAULT_PAGE_SIZE,
                  },
                  'replaceIn'
                )
              }
              onNextClick={() =>
                setQuery(
                  {
                    offset: promotionCodesData?.nextOffset,
                  },
                  'replaceIn'
                )
              }
            />
          )}
        </section>
        {metadata && Object.keys(metadata).length > 0 && (
          <section className={styles['meta-container']}>
            <h2>{t('coupon.metadata')}</h2>
            {Object.keys(metadata).map((key) => {
              return (
                <div key={key}>
                  <div>{key}</div>
                  <div>{metadata[key]}</div>
                </div>
              );
            })}
          </section>
        )}
      </div>
      {isShowModal && (
        <CreatePromotionCodeModal
          couponId={couponId}
          onCreatePromotionCodeSuccessful={refetchPromotionCodes}
          onDismiss={() => setIsShowModal(false)}
        />
      )}
    </Layout>
  );
};

export default CouponDetailScreen;

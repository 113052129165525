import { DEFAULT_PAGE_SIZE } from '../constants';
import { Coupon, PromotionCode } from '../types/coupon';
import baseAPI, { ListResponse, PageParams, TestParams } from './base';

const defaultParams = {
  test: true,
  offset: 0,
  limit: DEFAULT_PAGE_SIZE,
};

export interface GetCouponsParams extends TestParams, PageParams {
  merchant?: string;
}

export interface GetCouponParams {
  id: string;
  merchant?: string;
}

export interface GetPromotionCodesParams extends TestParams, PageParams {
  couponId: string;
  merchant?: string;
}

export interface GetPromotionCodeParams {
  id: string;
  merchant?: string;
}

export const couponAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    coupons: builder.query<ListResponse<Coupon>, GetCouponsParams>({
      query: (params) => {
        return {
          url: `/me/merchant/coupons`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    corporateCoupons: builder.query<ListResponse<Coupon>, GetCouponsParams>({
      query: (params) => {
        return {
          url: `/me/corporate-account/coupons`,
          params: {
            ...defaultParams,
            ...params,
          },
        };
      },
    }),
    coupon: builder.query<Coupon, GetCouponParams>({
      query: ({ id, merchant }) => ({
        url: `/me/merchant/coupons/${id}`,
        params: { merchant },
      }),
    }),
    corporateCoupon: builder.query<Coupon, GetCouponParams>({
      query: ({ id, merchant }) => ({
        url: `/me/corporate-account/coupons/${id}`,
        params: { merchant },
      }),
    }),
    promotionCodes: builder.query<
      ListResponse<PromotionCode>,
      GetPromotionCodesParams
    >({
      query: (params) => {
        const { couponId, ...restParams } = params;

        return {
          url: `/me/promotion-codes`,
          params: {
            ...restParams,
            // Keep using couponId for better readability
            coupon: couponId,
          },
        };
      },
    }),
    promotionCode: builder.query<PromotionCode, GetPromotionCodeParams>({
      query: ({ id, merchant }) => {
        return {
          url: `/me/promotion-codes/${id}`,
          params: { merchant },
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCouponsQuery,
  useCorporateCouponsQuery,
  useCouponQuery,
  useCorporateCouponQuery,
  usePromotionCodesQuery,
  usePromotionCodeQuery,
} = couponAPI;

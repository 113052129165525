import { navigate, RouteComponentProps } from '@reach/router';
import Mixpanel, { useTrackPageView } from '@smartpay/mixpanel';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash.isempty';
import sum from 'lodash.sum';
import cx from 'classnames';

import Capsule from '../../components/Capsule/Capsule';
import Button from '../../components/Form/Button';
import LabeledComponent from '../../components/Form/LabeledComponent';
import Layout from '../../components/Layout';
import Loading from '../../components/Loading';
import { ORDER_DETAIL_SCREEN } from '../../constants';
import { formatCurrency, useDataModeChanged } from '../../utils/helper';
import CancelOrderModal from './CancelOrderModal';
import ManualCaptureModal from './ManualCaptureModal';
import ReceiptView from './ReceiptView';
import RefundModal, { getFirstRefundablePayment } from './RefundModal';
import CopyableText from '../../components/CopyableText/CopyableText';
import {
  getCurrentFee,
  getFeeAmount,
  getCunsumerPayBaseAmount,
  getOrderEvents,
  getCapturedAmount,
  getRefundedAmount,
  getSponsoredDiscountAmount,
  getCancelledAmount,
  OrderEvent,
  getMerchantPayoutAmount,
} from './utils';
import sponsoredSrc from '../../assets/icon-sponsored.svg';
import Time from '../../components/Time/Time';
import { FeePlan } from '../../types/setting';
import {
  Order,
  OrderDisplayStatus,
  PaymentTransaction,
} from '../../types/order';
import {
  useMerchantFeePlanQuery,
  useMerchantOrderQuery,
  useMerchantTransactionsQuery,
} from '../../hooks/use-merchant-queries';

import styles from './OrderDetailScreen.module.scss';

interface OrderDetailProps extends RouteComponentProps {
  orderId?: string;
}

const screen = ORDER_DETAIL_SCREEN;

const Event = ({ event }: { event: OrderEvent }) => {
  const { t } = useTranslation('translation');

  return (
    <div
      key={`event-${event.time}`}
      className={cx(styles.box, styles.event, styles[event.type])}
    >
      <div className={styles.lhs}>
        <Capsule title={t(`order.${event.type}`)} status={event.type} />
        <Time dateTime={event.time} />
      </div>
      <div>
        <b className={styles.amount}>{formatCurrency(event.amount)}</b>
      </div>
    </div>
  );
};

const useOrderDetail = ({
  order,
  transactions,
  feePlan,
}: {
  order?: Order;
  transactions?: PaymentTransaction[];
  feePlan?: FeePlan;
}) => {
  return useMemo(() => {
    const isTestOrder = /_test_/.test(order?.id || '');
    const events = getOrderEvents(order);
    const cancelEvent = order?.canceledAt
      ? ({
          type: 'canceled',
          amount: getCancelledAmount(order),
          currency: order.currency,
          time: order.canceledAt,
        } as OrderEvent)
      : null;

    const capturable =
      order?.status === 'requires_capture' && order?.payments?.length === 0;
    const refundable = getFirstRefundablePayment(order).refundableAmount > 0;

    const cunsumerPayBaseAmount = getCunsumerPayBaseAmount(order);

    const realFeeAmount = transactions?.length
      ? transactions.reduce((amount, paymentTransaction) => {
          return (
            amount +
            sum(
              paymentTransaction.transactions
                .filter((t) => t.kind === 'payment')
                .map((t) => t.feeFixedAmount + t.feeRateAmount)
            )
          );
        }, 0)
      : 0;

    // const unsponsoredDiscountAmount = getUnsponsoredDiscountAmount(order);
    // Smartpay sponsered
    const sponsoredDiscountAmount = getSponsoredDiscountAmount(order);
    const refundedAmount = getRefundedAmount(order);
    const capturedAmount = getCapturedAmount(order);
    // The amount we captured
    // if the status is requires_capture
    //   use the expected amount value
    // else
    //   use the real amount we captured
    const cunsumerPayAmount = (() => {
      switch (order?.status) {
        case 'requires_capture':
          return cunsumerPayBaseAmount;
        default:
          return Math.max(0, capturedAmount - refundedAmount);
      }
    })();

    const feeBaseAmount = (() => {
      switch (order?.status) {
        case 'requires_capture':
          return cunsumerPayBaseAmount + sponsoredDiscountAmount;
        default:
          return capturedAmount;
      }
    })();

    const fee = getCurrentFee(feePlan);
    const merchantPayoutBaseAmount =
      cunsumerPayAmount + sponsoredDiscountAmount;
    // The fee we take from this order
    const expectedFeeAmount = getFeeAmount(feeBaseAmount, fee);
    // The amount the merchant received
    const merchantPayoutAmount = getMerchantPayoutAmount(
      merchantPayoutBaseAmount,
      realFeeAmount,
      expectedFeeAmount
    );

    const displayStatus = (() => {
      switch (order?.displayStatus) {
        case 'succeeded':
          return capturedAmount === cunsumerPayBaseAmount
            ? 'succeeded'
            : 'partially_captured';
        default:
          return order?.displayStatus as OrderDisplayStatus;
      }
    })();

    return {
      isTestOrder,
      events,
      cancelEvent,
      capturable,
      refundable,
      sponsoredDiscountAmount,
      cunsumerPayAmount,
      fee,
      realFeeAmount,
      expectedFeeAmount,
      merchantPayoutAmount,
      displayStatus,
    };
  }, [order, transactions, feePlan]);
};

const OrderDetailScreen: FC<OrderDetailProps> = ({ orderId = '' }) => {
  useTrackPageView({ screen });

  const [isShowRefundModal, setIsShowRefundModal] = useState(false);
  const [isShowCaptureModal, setIsShowCaptureModal] = useState(false);
  const [isShowCancelModal, setIsShowCancelModal] = useState(false);

  useDataModeChanged({
    id: orderId,
    onDataModeChanged: () => {
      setIsShowRefundModal(false);
      setIsShowCaptureModal(false);
      setIsShowCancelModal(false);
      navigate('/orders');
    },
  });
  const { t } = useTranslation('translation');
  const {
    data: order,
    refetch,
    isLoading,
  } = useMerchantOrderQuery({ id: orderId }, { skip: !orderId });
  const { data: transactions } = useMerchantTransactionsQuery(
    { id: orderId },
    { skip: !orderId }
  );
  const { data: feePlan } = useMerchantFeePlanQuery();
  const hasMetadata = !isEmpty(order?.metadata);

  const {
    isTestOrder,
    events,
    cancelEvent,
    capturable,
    refundable,
    sponsoredDiscountAmount,
    cunsumerPayAmount,
    fee,
    realFeeAmount,
    expectedFeeAmount,
    merchantPayoutAmount,
    displayStatus,
  } = useOrderDetail({ order, transactions, feePlan });

  const feeAmount = realFeeAmount || expectedFeeAmount;

  return (
    <Layout mainClassName={styles.main}>
      <div className={styles['navigation-bar']}>
        <Button
          id="btn_back"
          label={t('back-btn')}
          variant="outline"
          size="small"
          onClick={() => {
            Mixpanel.trackAction({
              screen,
              action: 'Click',
              itemName: 'Back',
            });

            navigate(-1);
          }}
        />
        {order && (
          <>
            <h1>
              {formatCurrency(
                merchantPayoutAmount || cunsumerPayAmount,
                order.currency
              )}
            </h1>
            <Capsule
              title={t(`order.status.${displayStatus}`)}
              status={displayStatus}
            />
          </>
        )}
        <CopyableText id="order_id" className={styles['order-id-tag']}>
          {orderId}
        </CopyableText>
      </div>
      {isLoading ? (
        <div className={styles['order-detail-container']}>
          <Loading width={48} top={48} />
        </div>
      ) : null}
      {!isLoading && order && (
        <div className={styles['order-detail-container']}>
          <section>
            <h2>{t('order.order-details')}</h2>
            <div className={cx(styles.panel)}>
              <div className={cx(styles['order-detail'])}>
                <LabeledComponent
                  className={styles['order-reference']}
                  label={t('order.reference')}
                >
                  {order?.reference ? (
                    <CopyableText>{order.reference}</CopyableText>
                  ) : (
                    'n/a'
                  )}
                </LabeledComponent>
                <LabeledComponent label={t('order.date')}>
                  <Time
                    dateTime={order?.createdAt}
                    formatStr={t(
                      'format.yyyyMMMddHHmmss',
                      'yyyy/MM/dd HH:mm:ss'
                    )}
                  />
                </LabeledComponent>
                <LabeledComponent
                  label={t('order.consumer')}
                  value={order.customerEmail || 'n/a'}
                  className={styles.consumer}
                />
              </div>

              {(order.description || hasMetadata) && (
                <div className={cx(styles['order-detail'])}>
                  {order.description && (
                    <LabeledComponent
                      label={t('order.description')}
                      value={order.description ?? ''}
                    />
                  )}

                  {hasMetadata && (
                    <LabeledComponent
                      label={t('order.meta')}
                      className={styles['meta-container']}
                    >
                      {Object.keys(order.metadata || {}).map((key) => {
                        return (
                          <div key={key}>
                            <div>{key}</div>
                            <div>{order.metadata?.[key]}</div>
                          </div>
                        );
                      })}
                    </LabeledComponent>
                  )}
                </div>
              )}
            </div>
          </section>
          <section className={styles.cols}>
            <section>
              {!!cancelEvent && (
                <>
                  <h2>{t('order.cancel-history')}</h2>
                  <div className={cx(styles.panel, styles.events)}>
                    <Event event={cancelEvent} />
                  </div>
                </>
              )}
              <h2>{t('order.transaction-details')}</h2>
              <div className={cx(styles.panel, styles.events)}>
                {sponsoredDiscountAmount > 0 && (
                  <div className={cx(styles.box, styles.sponsored)}>
                    <div className={styles.lhs}>
                      <img width="24" height="24" alt="" src={sponsoredSrc} />
                      <b>Smartpay sponsored</b>
                    </div>

                    <div>
                      <b className={styles.amount}>
                        {formatCurrency(sponsoredDiscountAmount)}
                      </b>
                    </div>
                  </div>
                )}
                {events.map((event) => (
                  <Event event={event} />
                ))}

                {isTestOrder || fee === undefined || feeAmount === undefined ? (
                  <div className={cx(styles.box)}>
                    <div className={cx(styles.lhs)}>
                      <h3>{t('order.smartpay-fee')}</h3>
                      <span>{t('order.test-no-fee')}</span>
                    </div>
                    <div className={cx(styles.amount)}>-￥ XXX</div>
                  </div>
                ) : (
                  <div className={cx(styles.box)}>
                    <div className={cx(styles.lhs)}>
                      <h3>{t('order.smartpay-fee')}</h3>
                      <span>
                        <strong>{fee.rate * 100}%</strong>
                        {fee.fixedAmount > 0 && (
                          <>
                            <i>+</i>{' '}
                            <span className={styles['fixed-fee']}>
                              {formatCurrency(fee.fixedAmount, order.currency)}
                            </span>
                          </>
                        )}
                      </span>
                    </div>
                    <div className={cx(styles.amount)}>
                      {formatCurrency(-feeAmount)}
                    </div>
                  </div>
                )}

                <div className={styles['action-container']}>
                  {capturable && (
                    <>
                      <Button
                        label={t('order.capture')}
                        size="small"
                        onClick={() => {
                          Mixpanel.trackAction({
                            screen,
                            action: 'Click',
                            itemName: 'Show Manual Capture Modal',
                          });
                          setIsShowCaptureModal(true);
                        }}
                      />
                      <Button
                        label={t('order.cancel')}
                        variant="outline"
                        size="small"
                        onClick={() => {
                          Mixpanel.trackAction({
                            screen,
                            action: 'Click',
                            itemName: 'Show Cancel Order Modal',
                          });
                          setIsShowCancelModal(true);
                        }}
                      />
                    </>
                  )}
                  {order.status === 'succeeded' && refundable && (
                    <Button
                      id="btn_create_refund"
                      label={t('order.receipt.refund-btn')}
                      size="small"
                      className={styles['refund-btn']}
                      onClick={() => {
                        Mixpanel.trackAction({
                          screen,
                          action: 'Click',
                          itemName: 'Show Refund Modal',
                        });
                        setIsShowRefundModal(true);
                      }}
                    />
                  )}
                </div>
              </div>
              {isTestOrder && (
                <div className={styles.panel}>
                  <div
                    className={cx(styles['amount-box'], styles['merchant-pay'])}
                  >
                    <p className={styles.note}>
                      {t('order.no-merchant-payout-amount-on-test')}
                    </p>
                  </div>
                </div>
              )}
              {!isTestOrder && merchantPayoutAmount !== undefined && (
                <div className={styles.panel}>
                  <div
                    className={cx(styles['amount-box'], styles['merchant-pay'])}
                  >
                    <div className={styles.line}>
                      <div className={styles.label}>
                        {t('order.merchant-payout-amount')}
                      </div>
                      <div className={cx(styles.value, styles[order.status])}>
                        {formatCurrency(merchantPayoutAmount)}
                      </div>
                    </div>
                    <hr />
                    <p className={styles.note}>
                      {t('order.merchant-payout-amount-note')}
                    </p>
                  </div>
                </div>
              )}
            </section>
            <section>
              <h2>{t('order.customer-bills')}</h2>
              <div className={styles.panel}>
                <ReceiptView order={order} />
              </div>
              <div className={styles.panel}>
                <div className={cx(styles['amount-box'], styles.alt)}>
                  <div className={styles.line}>
                    <div className={styles.label}>
                      {t('order.consumer-pay-amount')}
                      {order.status === 'requires_capture' && (
                        <Capsule
                          title={t(`order.onhold`)}
                          status="onhold"
                          className={styles.onhold}
                        />
                      )}
                    </div>
                    <div className={cx(styles.value, styles[order.status])}>
                      {formatCurrency(cunsumerPayAmount)}
                    </div>
                  </div>
                  <hr />
                  <p className={styles.note}>
                    {t('order.consumer-pay-amount-note')}
                  </p>
                </div>
              </div>
            </section>
          </section>
        </div>
      )}
      {order && isShowRefundModal && (
        <RefundModal
          order={order}
          onRefundSuccessful={refetch}
          onDismiss={() => setIsShowRefundModal(false)}
        />
      )}

      {order && isShowCaptureModal && (
        <ManualCaptureModal
          order={order}
          onCaptureSuccessful={refetch}
          onDismiss={() => setIsShowCaptureModal(false)}
        />
      )}

      {order && isShowCancelModal && (
        <CancelOrderModal
          order={order}
          onCancelSuccessful={refetch}
          onDismiss={() => setIsShowCancelModal(false)}
        />
      )}
    </Layout>
  );
};

export default OrderDetailScreen;

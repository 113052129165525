import classNames from 'classnames';
import { useContextMenu } from 'react-contexify';
import { useTranslation } from 'react-i18next';

import Time from '../../components/Time/Time';
import { User } from '../../types/user';

import srcDots from '../../assets/icon-dots.svg';

import styles from './UserRow.module.scss';

const UserRow = ({ user }: { user: User }) => {
  const { t } = useTranslation('translation');

  const { show } = useContextMenu({
    id: 'user_context_menu',
  });

  return (
    <tr className={styles.user}>
      <td>{user.username}</td>
      <td>
        <div className={styles.roles}>
          {user.roles.map((role) => (
            <span
              key={role}
              className={classNames(styles.role, styles[role])}
              title={t(`user-listing.permission-alt.${role}`)}
            >
              {t(`user-listing.permission.${role}`)}
            </span>
          ))}
        </div>
      </td>
      <td>
        <Time
          dateTime={user.createdAt}
          onEmpty="ー"
          formatDateStr="y/MM/dd"
          formatTimeStr="HH:mm"
        />
      </td>
      <td>
        <button
          type="button"
          onClick={(event) => {
            event?.stopPropagation();

            show({
              event,
              props: { id: user.id },
            });
          }}
        >
          <img src={srcDots} width={16} height={16} alt="" loading="lazy" />
        </button>
      </td>
    </tr>
  );
};

export default UserRow;
